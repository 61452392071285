
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { setTimeout } from 'timers'
import TableCard from './Cards/TableCard.vue'
import PieChartCard from './Cards/PieChartCard.vue'
import AreaChartCard from './Cards/AreaChartCard.vue'
import TaskCard from './Cards/TaskCard.vue'

@Component({
  components: {
    Widget,
    TableCard,
    PieChartCard,
    AreaChartCard,
    TaskCard,
    IconAction,
    SelectPicker,
  },
})
export default class Card extends ViewModel {
  @Ref() private readonly card!: any

  @Prop({ default: '' })
  public title!: string

  @Prop({ default: '' })
  public subTitle!: string

  @Prop({ required: true })
  public type!: string

  @Prop({ default: 'large' })
  public size!: string

  @Prop({ required: true })
  public settings!: any

  @Prop({ default: () => [] })
  public actions!: any

  @Prop({ default: () => null })
  public toggle_options!: any

  private busy: boolean = false

  public show_popover: boolean = false

  public should_watch: boolean = false

  public selected_metric: any = {}

  @Watch('selected_metric')
  public onSelectedMEtricChange(val: any) {
    if (val && this.should_watch) {
      this.updateMetrics()
    }
  }

  private get colSize() {
    switch (this.size) {
      case 'xs':
        return {
          lg: 2,
          md: 4,
          sm: 12,
          xs: 12,
        }
      case 'sm':
        return {
          lg: 4,
          md: 6,
          sm: 12,
          xs: 12,
        }
      case 'md':
        return {
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        }
      case 'lg':
        return {
          lg: 8,
          md: 8,
          sm: 12,
          xs: 12,
        }
      default:
        return {
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        }
    }
  }

  public refresh() {
    this.card.refresh()
  }

  private trigger(event: string) {
    this.card.trigger(event)
  }

  mounted() {
    if (this.toggle_options) {
      if (this.toggle_options.hasOwnProperty('default_index_option')) {
        this.selected_metric = this.toggle_options.data[this.toggle_options.default_index_option].value
        this.$emit('update:settings', Object.assign(this.settings, this.selected_metric))
        setTimeout(() => {
          this.should_watch = true
        }, 1000)
      }
    }
  }

  /**
   * If you use the Metric toggle
   *
   * Call this function after toggle change
   */
  public updateMetrics() {
    this.$emit('update:settings', Object.assign(this.settings, this.selected_metric))
    this.card.checkForUpdates()
    this.card.refresh()
  }
}
