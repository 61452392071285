
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Task from '@/models/Task'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { clone } from 'lodash'
import { querySettings } from '@/models/metadata/TaskMetadata'
import EditTask from '@/pages/Task/components/EditTask.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import TaskOwnerSelector from './components/TaskOwnerSelector.vue'
import StatusSelector from './components/StatusSelector.vue'
import UpcomingTasks from './components/UpcomingTasks.vue'
import TaskComments from './components/TaskComments.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
    TaskOwnerSelector,
    StatusSelector,
    UpcomingTasks,
    TaskComments,
    CustomIcon,
  },
})
export default class TaskMonday extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly upcomingTable!: UpcomingTasks

  @Ref() readonly commentSideBar!: TaskComments

  public ready: boolean = false

  public task: Task = new Task()

  public records: number = 0

  public loading: boolean = false

  public show_filter_helper: boolean = false

  public query: string[] = ['is:mine', 'is_not:completed']

  public selected: string[] = []

  public fieldFilters: any = {
    due_at: '',
    completed_at: '',
    created_at: '',
    updated_at: '',
    status_name: '',
    type: '',
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public table_data: Task[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get query_settings() {
    return querySettings
  }

  public get action_field() {
    const found = Task.tableFields.find(f => f.key === 'action')
    found.actions = found.actions.filter(f => f.show || f.show())
    return found
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Task.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public editTask(task: any = null) {
    let Component = Vue.extend(EditTask)
    let instance = new Component({
      propsData: {
        dataTable: this.dataTable,
        parentId: task.parent_id,
        id: task.id,
        mode: 'edit',
      },
    })
    instance.$mount()
  }

  public createTask() {
    let Component = Vue.extend(EditTask)
    let instance = new Component({
      propsData: {
        dataTable: this.dataTable,
        hideTypes: ['request_media_plan'],
        initialPayload: { type: 'marketing_material' },
      },
    })
    instance.$mount()
  }

  public refresh(refresh_upcoming: boolean = false) {
    if (refresh_upcoming && this.upcomingTable) {
      this.upcomingTable.refresh()
    }
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {
      due_at: '',
      completed_at: '',
      created_at: '',
      updated_at: '',
      status_name: '',
      type: '',
    }
    this.filter_mode = 'exclusive'
    this.query = ['is:mine', 'is_not:completed']
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'tasks',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('tasks').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'tasks',
      data: null,
    })
  }

  public mounted() {
    this.loadFilters()
    this.fields = clone(Task.tableFields)

    if (this.$route.params.preview && this.$route.params.preview.length > 30) {
      let Component = Vue.extend(EditTask)
      let instance = new Component({
        propsData: {
          id: this.$route.params.preview,
          dataTable: this,
        },
      })
      instance.$mount()
    }
  }

  public triggerAction(event: string, item: any) {
    this.dataTable.triggerAction(event, item)
  }

  public previewComments(task: Task) {
    this.commentSideBar.cancel()
    this.commentSideBar.open = false
    this.task = task
    setTimeout(() => {
      this.commentSideBar.open = true
    }, 500)
  }
}
