
import Widget from '@/components/Widget/Widget.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import apexchart from 'vue-apexcharts'
import ViewModel from '@/models/ViewModel'
import Api from '@/models/Api'

@Component({
  components: {
    Widget,
    apexchart,
  },
})
export default class PieChartCard extends ViewModel {
  @Prop({ default: false })
  public busy!: boolean

  @Prop({ required: true })
  public settings!: any

  public payload: any = null

  public loaded: boolean = false

  public chartOptions: any = {
    chart: {
      animations: {
        enabled: !this.user.isSystem,
      },
    },
    labels: [],
    legend: {
      position: 'right',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      enabled: true,
      marker: {
        show: false,
      },
    },
  }

  private get localBusy() {
    return this.busy
  }

  private set localBusy(value: boolean) {
    this.$emit('update:busy', value)
  }

  public series: any = []

  public labels: any = []

  public colors: any = []

  private period: string = ''

  public mounted() {
    this.refresh()
  }

  public reloadChart() {
    if (!this.series) return

    let fill = {}
    if (this.colors) {
      fill = {
        legend: {
          markers: {
            fillColors: this.colors,
          },
        },
        fill: { colors: this.colors },
      }
    }
    this.chartOptions = {
      ...this.chartOptions,
      ...{
        labels: this.labels,
        ...fill,
      },
    }

    this.loaded = true
  }

  public refresh() {
    const api = new Api(false)
    this.localBusy = true
    api
      .post(`${this.settings.model_name}/chart-card`, {
        type: 'pie',
        group: this.settings.group,
        metric: this.settings.metric,
        metric_column: this.settings.metric_column,
        period: this.settings.period,
        filters: this.settings.filters,
      })
      .then((response: any) => {
        this.loaded = false
        this.series = response.data.result.series
        this.labels = response.data.result.labels
        this.period = response.data.result.period
        this.colors = response.data.result.colors
        this.reloadChart()
        this.localBusy = false
      })
      .catch(() => {
        this.localBusy = false
      })
  }
}
