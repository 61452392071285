
import Widget from '@/components/Widget/Widget.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import apexchart from 'vue-apexcharts'
import ViewModel from '@/models/ViewModel'
import Api from '@/models/Api'
import { clone, startCase, uniqueId } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    Widget,
    apexchart,
  },
})
export default class AreaChartCard extends ViewModel {
  @Prop({ default: false })
  public busy!: boolean

  @Prop({ default: 'area' })
  public type!: string

  @Prop({ required: true })
  public settings!: any

  public payload: any = null

  public loaded: boolean = false

  public backup_chartOptions: any = {}

  public chartOptions: any = {
    chart: {
      toolbar: false,
      stacked: false,
      height: 500,
      animations: {
        enabled: !this.user.isSystem,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: [
      {
        seriesName: 'Impressions',
        title: {
          text: 'Impressions',
        },
        labels: {
          formatter(value: number) {
            return Math.ceil(value)
          },
        },
      },
    ],
  }

  private get localBusy() {
    return this.busy
  }

  private set localBusy(value: boolean) {
    this.$emit('update:busy', value)
  }

  public series: any = []

  public labels: any = []

  private period: string = ''

  public mounted() {
    this.backup_chartOptions = clone(this.chartOptions)
    this.refresh()
  }

  /**
   * If you use the Metric toggle, after user selected other metric column
   * call this function to check conditional formatting
   */
  // TODO add this to the new branch my-dashboard
  public checkForUpdates() {
    if (this.settings.hasOwnProperty('formatters')) {
      // let cond = this.settings.formatters.conditions[0]
      // if (cond && cond.key) {
      //   if (this.settings[cond.key] == cond.value) {
      //     this.chartOptions = Object.assign(this.chartOptions, {
      //       ...cond.result,
      //     })
      //   } else {
      //     this.chartOptions = this.backup_chartOptions
      //   }
      // }

      this.settings.formatters.conditions.forEach(cond => {
        if (cond && cond.key) {
          if (this.settings[cond.key] == cond.value) {
            this.chartOptions = Object.assign(this.chartOptions, {
              ...cond.result,
            })
          } else {
            // probably use a checksum
            // check if chartOptions == backup_chartOptions
            // if not restor else ignore
            this.chartOptions = this.backup_chartOptions
          }
        }
      })
    }
  }

  public reloadChart() {
    if (!this.series) return

    this.chartOptions = {
      ...this.chartOptions,
      ...{
        chart: {
          ...this.chartOptions.chart,
          ...{ stacked: this.settings.stacked ?? false },
        },
        plotOptions: {
          bar: {
            horizontal: this.settings.horizontal ?? false,
          },
        },
        labels: this.labels,
        yaxis: [
          {
            seriesName: startCase(this.settings.metric.replaceAll('_', ' ')),
            title: {
              text: startCase(this.settings.metric.replaceAll('_', ' ')),
            },
            labels: {
              formatter(value: number) {
                return Math.ceil(value)
              },
            },
          },
        ],
      },
    }

    this.loaded = true
  }

  public refresh() {
    const api = new Api(false)
    this.localBusy = true
    this.loaded = false
    this.checkForUpdates()
    api
      .post(`${this.settings.model_name}/chart-card`, {
        type: 'area',
        xaxis: this.settings.xaxis,
        yaxis: this.settings.yaxis,
        metric: this.settings.metric,
        period: this.settings.period,
        filters: this.settings.filters,
        format: this.settings.format,
        metric_column: this.settings.metric_column ?? null,
      })
      .then((response: any) => {
        this.loaded = true
        this.series = response.data.result.series
        this.labels = response.data.result.labels
        this.period = response.data.result.period
        this.reloadChart()
        this.localBusy = false
      })
      .catch(() => {
        this.localBusy = false
      })
  }
}
