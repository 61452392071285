
import { Component, Prop, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import { clone, startCase } from 'lodash'

@Component({
  components: {
    DataTable,
  },
})
export default class TableCard extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop({ required: true })
  public settings!: any

  @Prop({ default: false })
  public busy!: boolean

   @Prop({ default: () => [] })
  public actions!: any

  public records: number = 0

  private fieldFilters: any = {}

  private data: any = []

  public query: string[] = []

  public selected: string[] = []

  private get model() {
    return this.settings.model
  }

  private get localBusy() {
    return this.busy
  }

  private set localBusy(value: boolean) {
    this.$emit('update:busy', value)
  }

  public get show_fields() {
    return this.settings.model.tableFields
      .map((field: any) => {
        field.show = this.settings.fields.includes(field.key)
        if (field.key === 'action') {
          field.actions = field.actions.filter((a:any) => a.action !== undefined)
        }
        return field
      })
      .filter((f: any) => f.show === undefined || f.show)
  }

  public rows(context: any) {
    this.localBusy = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return this.model
      .paginate({
        page_size: context.perPage,
        page: context.currentPage,
        order_by: context.sortBy,
        order: context.sortDesc ? 'desc' : 'asc',
        query: [...context.filter, ...field_filters],
      })
      .then((result: any) => {
        this.records = result.records
        this.localBusy = false
        this.data = result.data
        return result.data
      })
  }

  public trigger(event:string) {
    let action = this.actions.find((a:any) => a.event === event)
    if (action && action.action !== undefined) {
      action.action(this.data.filter((item:any) => this.selected.includes(item.id)))
    }
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public created() {
    this.fieldFilters = clone(this.settings.filters)
  }
}
