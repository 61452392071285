
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import { uniqueId } from 'lodash'
import settings from './settings'
import Card from './components/Card.vue'

@Component({
  components: {
    Card,
    IconAction,
  },
})
export default class Dashboard extends ViewModel {
  private activeViewId: string = ''

  public tabIndex: number = 0

  public temp_name: string = ''

  public card_options: any = [
    {
      icon: 'las la-table',
      name: 'Table',
      type: 'table',
    },
    {
      icon: 'las la-chart-pie',
      name: 'Pie Chart',
      type: 'pie-chart',
    },
    {
      icon: 'lar la-chart-bar',
      name: 'Bar Chart',
      type: 'bar-chart',
    },
    {
      icon: 'las la-chart-area',
      name: 'Area Chart',
      type: 'Area-chart',
    },
  ]

  public customViews: any = []

  @Watch('tabIndex')
  public onTabIndexChange(val) {
    this.updateQuery()
  }

  public get settings() {
    // @ts-ignore
    return settings.account_managers
  }

  public get views() {
    let views = [...this.settings.views, ...this.customViews]
    return views.filter((view: any) => !view.hasOwnProperty('visible') || view.visible())
  }

  public get activeView() {
    return this.views.find((view: any) => view.id === this.activeViewId)
  }

  public created() {
    this.activeViewId = this.views[0].id
  }

  public mounted() {
    setTimeout(() => {
      this.getViewByUrlRef()
    }, 500)
  }

  public getViewByUrlRef() {
    if (this.$route.query.tab) {
      let found = this.views.find((view: any, index: number) => {
        if (view.name === this.$route.query.tab.replaceAll('_', ' ')) {
          this.tabIndex = index
          return view
        }
        return false
      })!
      this.activeViewId = found.id
    }
  }

  public updateQuery() {
    let found = this.views[this.tabIndex]
    this.$router.push({ name: 'LegacyDashboard', query: { tab: found.name.replaceAll(' ', '_') } })
  }

  public cancelNewView() {
    this.$bvModal.hide('new-view-modal')
    this.temp_name = ''
  }

  public confirmNewView() {
    let base = {
      id: uniqueId(),
      name: this.temp_name,
      cards: [],
    }
    this.activeViewId = base.id
    this.customViews.push(base)
    setTimeout(() => {
      this.tabIndex += 1
      this.cancelNewView()
    }, 500)
  }
}
