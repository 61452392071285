<script>
import Vue from 'vue'

export default {
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const anchors = this.$el.getElementsByTagName('a') // Not iterable

    Array.from(anchors).forEach(anchor => {
      const url = anchor.getAttribute('href')

      // Skip external links
      if (!url.startsWith('https://dashboard.castiron.media')) return
      const path = url.replace('https://dashboard.castiron.media', '')

      // https://vuejs.org/v2/api/#propsData
      const propsData = { to: path }
      // https://vuejs.org/v2/api/#parent
      // Without parent context RouterLink will be unable to access this.$router, etc.
      const parent = this

      const Component = Vue.component('RouterLink')

      const routerLink = new Component({ propsData, parent })

      routerLink.$mount(anchor) // Replaces anchor element
      routerLink.$el.innerHTML = anchor.innerHTML
    })
  },
  render(createElement) {
    // https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
    const options = {
      domProps: { innerHTML: this.htmlContent },
    }
    return createElement('div', options)
  },
}
</script>
