
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Task from '@/models/Task'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { clone } from 'lodash'
import { querySettings } from '@/models/metadata/TaskMetadata'
import EditTask from '@/pages/Task/components/EditTask.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import TaskOwnerSelector from './TaskOwnerSelector.vue'
import StatusSelector from './StatusSelector.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
    TaskOwnerSelector,
    StatusSelector,
    CustomIcon,
  },
})
export default class UpcomingTasks extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public ready: boolean = false

  public task: Task = new Task()

  public records: number = 0

  public loading: boolean = false

  public show_filter_helper: boolean = false

  public query: string[] = ['is:mine', 'is:upcoming']

  public selected: string[] = []

  public fieldFilters: any = {
    due_at: '',
    completed_at: '',
    created_at: '',
    updated_at: '',
    status_name: '',
    type: '',
  }

  public table_data: Task[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get query_settings() {
    return querySettings
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    // @ts-ignore
    return Task.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public editTask(task: any = null) {
    let Component = Vue.extend(EditTask)
    let instance = new Component({
      propsData: {
        dataTable: this.dataTable,
        parentId: task.parent_id,
        id: task.id,
        mode: 'edit',
      },
    })
    instance.$mount()
  }

  public createTask(id: any = null) {
    let Component = Vue.extend(EditTask)
    let instance = new Component({
      propsData: {
        dataTable: this,
        hideTypes: ['request_media_plan'],
        initialPayload: { type: 'marketing_material' },
        parentId: id,
      },
    })
    instance.$mount()
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {
      due_at: '',
      completed_at: '',
      created_at: '',
      updated_at: '',
      status_name: '',
      type: '',
    }
    this.query = ['is:mine', 'is_not:completed']
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'tasks',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('tasks').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'tasks',
      data: null,
    })
  }

  public mounted() {
    this.loadFilters()
    this.fields = clone(Task.tableFields)

    if (this.$route.params.preview && this.$route.params.preview.length > 30) {
      let Component = Vue.extend(EditTask)
      let instance = new Component({
        propsData: {
          id: this.$route.params.preview,
          dataTable: this,
        },
      })
      instance.$mount()
    }
  }

  public getInitials(name: string) {
    if (!name) return
    // Split the name by spaces
    const nameParts = name.trim().split(' ')

    // Get the first initial (always present)
    const firstInitial = nameParts[0].charAt(0)

    // Get the second initial if there is a second name part
    const secondInitial = nameParts.length > 1 ? nameParts[1].charAt(0) : ''

    // Concatenate the initials
    const initials = firstInitial + secondInitial

    return initials.toUpperCase() // Ensuring initials are in uppercase
  }

  public previewComments(task: Task) {
    this.$emit('preview-comment', task)
  }

  public triggerAction(event: string, item: any) {
    this.dataTable.triggerAction(event, item)
  }
}
